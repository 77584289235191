
[class^="blend"] img {
  mix-blend-mode: luminosity;
}

[class^="blend"]:before {
  position: absolute;
  z-index: 3;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 0.2em;
  font-size: 14px;
}

[class^="blend"]:after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-filter: contrast(1.3);
  filter: contrast(1.3);
  mix-blend-mode: hue;
}

[class^="blend"][class*="-dark"] img {
  mix-blend-mode: darken;
}

[class^="blend"][class*="-dark"]:after {
  mix-blend-mode: lighten !important;
}

[class^="blend"][class*="-light"] img {
  mix-blend-mode: lighten;
}

[class^="blend"][class*="-light"]:after {
  mix-blend-mode: darken !important;
}

[class^="blend"][class*="-red"] {
  background: #e50914;
}

[class^="blend"][class*="-red"]:after {
  mix-blend-mode: hard-light;
  -webkit-filter: contrast(0.6) saturate(120%) brightness(1.2);
  filter: contrast(0.6) saturate(120%) brightness(1.2);
}

[class^="blend"][class*="-red"][class*="-dark"]:after {
  mix-blend-mode: lighten !important;
  -webkit-filter: contrast(1.1) !important;
  filter: contrast(1.1) !important;
}

[class^="blend"][class*="-red"][class*="-light"]:after {
  mix-blend-mode: color-dodge !important;
  -webkit-filter: saturate(400%) contrast(1.5);
  filter: saturate(400%) contrast(1.5);
}

[class^="blend"][class*="-red"]:after {
  background: #e50914;
  background: #282581;
}


[class^="blend"][class*="-blue"] {
  background: #73a7d4;
}

[class^="blend"][class*="-blue"]:not([class*="-dark"]):not([class*="-light"]):after {
  mix-blend-mode: hard-light;
  -webkit-filter: brightness(0.6);
  filter: brightness(0.6);
}

[class^="blend"][class*="-blue"][class*="-dark"]:after {
  mix-blend-mode: darken !important;
}

[class^="blend"][class*="-blue"]:after {
  background: #73a7d4;
  background: #93ef90;
}


[class^="blend"][class*="-red-blue"]:not([class*="-dark"]):not([class*="-light"]) {
  background: linear-gradient(to bottom right, #3993e2, #e2544b);
}

[class^="blend"][class*="-red-blue"]:not([class*="-dark"]):not([class*="-light"]):after {
  -webkit-filter: none;
  filter: none;
  mix-blend-mode: hard-light;
}

[class^="blend"][class*="-red-blue"][class*="-dark"]:after {
  mix-blend-mode: hard-light !important;
  -webkit-filter: none !important;
  filter: none !important;
}

[class^="blend"][class*="-red-blue"][class*="-light"]:after {
  mix-blend-mode: screen !important;
  -webkit-filter: saturate(300%) brightness(1.2) !important;
  filter: saturate(300%) brightness(1.2) !important;
}

[class^="blend"][class*="-red-blue"]:after {
  background: linear-gradient(to bottom right, #3993e2, #e2544b);
}

.mynotes-page {
  background: linear-gradient(to bottom right, #3993e2, #e2544b);
}

body,
html {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  position: relative;
  font-family: Montserrat, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  position: relative;
}

body {
  background: #fff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  overflow-y: auto;
}

.make-it-fullwidth {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  -webkit-filter:grayscale(32%) contrast(.8); 
  filter:grayscale(32%) contrast(.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}

.bgimage-container {
  position: absolute;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bgimage-gradient-container {
  position: absolute;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(to bottom right, #3993e2, #e2544b);
  width: 100%;
  opacity: 0.5;
  display: none;
}

main{z-index: 9999; position: absolute;top:50%;left:50%;transform:translate(-50%,-50%);}
