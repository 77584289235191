.profile-container {
  color: #fff;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 26pt;
}

h3 {
  font-size: 22pt;
}

p {
  font-size: 14pt;
}

.fa {
  width: 32px;
  height: 32px;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  margin-top:5px;
}

.main-text{
  max-width:500px;
}
